export const theme = {
  colors: {
    primary: "#1971bd",
    lightPrimary: "#E5F3FA",
    darkPrimary: "#044D80",
    lightGrey: "#ECECE9",
    darkGrey: "#343534",
    sisterSitePrimary: "#c61e1e",
    sisterSiteDarkPrimary: "#931515",
  },
  fontFamily: "'Poppins', sans-serif",
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em",
  },
  breakpoints: {
    xs: "0",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1400px",
    xxl: "1600px",
  },
  categoryColor: "rgb(60, 111, 131)"
};
